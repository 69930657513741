
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { HomeFilled } from '@ant-design/icons-vue';
import { VERSION } from '../utils/constants';

export default defineComponent({
  name: 'Header',
  data() {
    return {
      version: VERSION,
    };
  },
  components: {
    HomeFilled,
  },
  methods: {
    onClick(s: string) {
      this.$router.push({ name: s });
    },
  },
  computed: {
    isClicked(): string {
      let page = 'Overview';
      if (this.$route.fullPath.includes('Surveys')) {
        page = 'Surveys';
      } else if (this.$route.fullPath.includes('Training')) {
        page = 'Training';
      }
      return page;
    },
  },
});
