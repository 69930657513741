/* eslint-disable */
export default {
  "range": "'Trainee Information'!A1:Z100",
  "majorDimension": "ROWS",
  "values": [
    [
      "Country of Training",
      "City of Training",
      "Latitude",
      "Longitude",
      "Region of Training",
      "Year of Training",
      "Month of Training",
      "Duration (Days)",
      "Level of Event",
      "Type of Event",
      "Level of Training",
      "Language of Event",
      "Gender of Trainee",
      "Trainee Organizational Affliation (Specific Name)",
      "Trainee Affiliation (category)",
      "Local Capacity",
      "Trainee Country of Work/Work Base",
      "SMART Certification",
      "Planning a SMART Survey?",
      "Date and location of planned SMART survey?",
      "Planning a SMART Training?",
      "Date of planned SMART training",
      "Planning to use SMART in National Survey Guidelines?"
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "ACF",
      "iNGO",
      "",
      "Malawi",
      "=COUNTIFS ('Trainee Information'!M2766"
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "ACF",
      "iNGO",
      "",
      "Uganda",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "ACF",
      "iNGO",
      "",
      "Uganda",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "ACF",
      "iNGO",
      "",
      "Uganda",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "ACF",
      "iNGO",
      "",
      "Uganda",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "ACF",
      "iNGO",
      "",
      "United States",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "ACF ",
      "iNGO",
      "",
      "Kenya",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "ACF ",
      "iNGO",
      "",
      "Sierra Leone",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "CPAR",
      "Local NGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "DDHS",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "",
      "GOAL",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "GOAL  ",
      "iNGO",
      "",
      "Malawi",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "IBFAN",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "IBFAN ",
      "iNGO",
      "",
      "Uganda",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "IMC",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "IMC",
      "iNGO",
      "",
      "Uganda",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "Makerere University - IMC / MUK Ouganda",
      "Academia",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "Masaka Hospital",
      "Other",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "Medair",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "MoH",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "MoH",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "MoH",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "MoH",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "",
      "MoH",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "",
      "MoH",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "MoH",
      "Government",
      "",
      "Kenya",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "MoH",
      "Government",
      "",
      "Malawi",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "M",
      "MoH",
      "Government",
      "",
      "Uganda",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "",
      "Mulago Hospital",
      "Other",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "RCQHC",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "UNICEF",
      "UN Agency",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "WFP",
      "UN Agency",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Uganda",
      "Kampala",
      "0.3476° N",
      "32.5825° E",
      "Eastern and Southern Africa",
      "2007",
      "8",
      "",
      "",
      "Training",
      "Survey Manager",
      "English",
      "F",
      "WVI",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF",
      "iNGO",
      "",
      "Mali",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF",
      "iNGO",
      "",
      "Niger",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF",
      "iNGO",
      "",
      "Niger",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "ACF ",
      "iNGO",
      "",
      "Spain",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "ACF ",
      "iNGO",
      "",
      "Spain",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF ",
      "iNGO",
      "",
      "Spain",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "Agrhymet",
      "Other",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "Agrhymet",
      "Other",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "CARE",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "DN",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "F",
      "GOAL",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "F",
      "HKI",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "HKI",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "INS",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "INS",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "INS",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "INS",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "INS",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "MoH",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "MoH",
      "Government",
      "",
      "Mauritania",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "F",
      "Sytème d'Alerte Précoce / Cabinet du Premier Ministre - SAP / CAB / PM",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "UNICEF",
      "UN Agency",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "UNICEF",
      "UN Agency",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "UNICEF",
      "UN Agency",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Niger",
      "Niamey",
      "13.5116° N",
      "2.1254° E",
      "West and Central Africa",
      "2007",
      "9",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "UNICEF",
      "UN Agency",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "M",
      "ACF",
      "iNGO",
      "",
      "Kenya",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "M",
      "ACF ",
      "iNGO",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "F",
      "ACF ",
      "iNGO",
      "",
      "Congo (Democratic Republic of)",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "",
      "ACF ",
      "iNGO",
      "",
      "Congo (Democratic Republic of)",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "M",
      "ACF ",
      "iNGO",
      "",
      "Sierra Leone",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "F",
      "ACF  ",
      "iNGO",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "M",
      "ACF  ",
      "iNGO",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "M",
      "ACF  ",
      "iNGO",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "M",
      "BPS ",
      "Government",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "",
      "BPS ",
      "Government",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "",
      "BPS ",
      "Government",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "",
      "Directeur LMTC",
      "Other",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "F",
      "LMTC Bujumbura",
      "Other",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "F",
      "LMTC Bujumbura",
      "Other",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "",
      "LMTC Bujumbura",
      "Other",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "",
      "LMTC Bujumbura",
      "Other",
      "",
      "Burundi",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "M",
      "MoH PRONANUT - DRC  Bukavu",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "",
      "MoH PRONANUT - DRC Kinshasa",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Burundi",
      "Gitega",
      "3.4273° S",
      "29.9246° E",
      "Eastern and Southern Africa",
      "2007",
      "11",
      "",
      "",
      "Training",
      "",
      "French",
      "F",
      "Point Focal Nut Ruyigi",
      "Other",
      "",
      "",
      "Not Implemented "
    ],
    ["", "", "#N/A", "#N/A"],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "F",
      "ACAUPED / Mamou",
      "Other",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "M",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF",
      "iNGO",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF ",
      "iNGO",
      "",
      "Spain",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF ",
      "iNGO",
      "",
      "Spain",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF ",
      "iNGO",
      "",
      "Spain",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ACF ",
      "iNGO",
      "",
      "Spain",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "ANCG",
      "Other",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "DCS / Matato",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "DFR / DRS Mamou",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "Direction Communale de la Santé / Direction de la Santé de la Ville de Conakry - DCS / DSVCO Ratoma",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "F",
      "DNS / MP",
      "Government",
      "",
      "",
      "Not Implemented "
    ],
    [
      "Guinea",
      "Conakry",
      "9.6412° N",
      "13.5784° W",
      "West and Central Africa",
      "2008",
      "7",
      "",
      "",
      "Training",
      "Survey Manager",
      "French",
      "",
      "DNS / MP",
      "Government",
      "",
      "",
      "Not Implemented "
    ]
  ]
}
