import {
  VuexModule,
  Module,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import { AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import fetch, { createSurveysQuery, overviewSurveyProps, locationProps } from '@/utils/request';
import { getUniqueTrainings } from '@/utils/functions';

export interface OverviewFilterProps {
  geographicScope: string;
  location: string;
  date: Dayjs[];
  data: overviewSurveyProps | undefined;
  trainingData: Array<string[]>;
  locationOptions: locationProps[],
  queryDiff: string[];
}

type loadingTypes = 'loading' | 'done' | 'inactive';

@Module({ namespaced: true })
class OverviewFilter extends VuexModule {
  public geographicScope = '';

  public location = '';

  public date = [] as Dayjs[];

  public data :overviewSurveyProps | undefined = undefined;

  public trainingData: Array<string[]> = [];

  public queryDiff: string[] = [];

  public locationOptions: locationProps[] = [];

  public loading:loadingTypes = 'inactive';

  @Mutation
  public setGeographicName(name: string):void {
    this.geographicScope = name;
  }

  @Action
  public updateGeographicName(name: string): void {
    this.context.commit('setGeographicName', name);
  }

  @Mutation
  public setLocation(name: string):void {
    this.location = name;
  }

  @Mutation
  public setQueryDiff(query:string[]):void {
    this.queryDiff = query;
  }

  @Action
  public updateLocation(name: string): void {
    this.context.commit('setLocation', name);
  }

  @Mutation
  public setLoading(loading: loadingTypes):void {
    this.loading = loading;
  }

  @Mutation
  public setTrainingData(values: Array<string[]>):void {
    this.trainingData = values;
  }

  @Mutation
  public setDate(dateRange: Dayjs[]):void {
    this.date = dateRange;
  }

  @Action
  public updateDate(dateRange: Dayjs[]): void {
    this.context.commit('setDate', dateRange || []);
  }

  @Mutation
  public setData(data: overviewSurveyProps):void {
    this.data = data;
  }

  @Action
  public updateTrainingFilterData(): void {
    const values = this.context.rootGetters['training/getDefaultValues'];
    let newValues = [] as Array<string[]>;
    if (!(this.date.length > 0 || this.location)) {
      newValues = values;
    } else {
      newValues.push(values[0]);
      let range = this.date;
      if (this.date.length > 0) {
        range = this.date.map((d: Dayjs) => {
          let x = d;
          if (x) {
            x = dayjs().year(d.year()).month(0).date(1);
          }
          return x;
        });
      }
      values.forEach((v: string[], valueIndex:number) => {
        if (valueIndex === 0) return;
        let isValid = true;
        let index;
        const indexes = [];
        if (isValid && this.location) {
          if (this.geographicScope === 'National') {
            indexes.push(values[0].indexOf('Country of Training'));
          } else if (this.geographicScope === 'Regional') {
            indexes.push(values[0].indexOf('Region of Training'));
          } else if (this.geographicScope === 'All' || !this.geographicScope) {
            indexes.push(values[0].indexOf('Region of Training'));
            indexes.push(values[0].indexOf('Country of Training'));
          }
          if (!indexes.includes(-1)) {
            let inScope = false;
            indexes.forEach((i:number) => {
              if (i <= v.length - 1 && v[i] && this.location === v[i].trim()) inScope = true;
            });
            if (!inScope) isValid = false;
          }
        }

        if (isValid && range.length > 0) {
          index = values[0].indexOf('Year of Training');
          const d = parseInt(v[index], 10);
          // from date
          if (range[0] && d) {
            isValid = d >= range[0].year();
          }
          if (range[1] && d && isValid) {
            isValid = d <= range[1].year();
          }
        }

        if (isValid) {
          newValues.push(v);
        }
      });
    }
    this.context.commit('setLoading', 'done');
    this.context.commit('setTrainingData', newValues);
  }

  @Action
  public async updateData(): Promise<void> {
    this.context.commit('setQueryDiff', [this.location, this.date]);
    await this.context.dispatch('updateOverview');
    await this.context.dispatch('updateTrainingFilterData');
  }

  @Action
  public async updateOverview(): Promise<any> {
    const surveysQuery = createSurveysQuery({
      date: this.date,
      location: this.location,
      geographicScope: this.geographicScope,
    });
    this.context.commit('setLoading', 'loading');
    return fetch('GET', 'overviewSurveys', null, { surveysQuery }).then((res) => {
      this.context.commit('setData', res.data);
      return res;
    }).catch((e) => (e));
  }

  @Mutation
  public setLocationOptions(Options: locationProps[]):void {
    this.locationOptions = Options;
  }

  @Action
  public async updateLocationOptions(): Promise<AxiosResponse> {
    return fetch('GET', 'locations', {})
      .then((res) => {
        this.context.commit('setLocationOptions', res.data);
      }).catch((e) => (e));
  }

  public get getDate(): Dayjs[] {
    return this.date;
  }

  public get getLocation(): string {
    return this.location;
  }

  public get getLocationOptions(): locationProps[] {
    return this.locationOptions;
  }

  public get getTrainingData(): Array<string[]> {
    return this.trainingData;
  }

  public get getLoading(): loadingTypes {
    return this.loading;
  }

  public get getGeographicScope(): string {
    return this.geographicScope;
  }

  public get getQueryDiff(): number {
    const queryCount = this.queryDiff;
    const currentCount = [this.location, this.date];
    const compare = currentCount.reduce((acc, curr, index) => {
      let a = acc;
      let queryItem:(string|number|Dayjs[]);
      let currItem:(string|number|Dayjs[]);
      if (!queryCount[index]) {
        queryItem = 0;
      } else if (queryCount[index].length > 0) {
        queryItem = queryCount[index];
      } else {
        queryItem = 0;
      }
      if (!currentCount[index]) {
        currItem = 0;
      } else if (currentCount[index].length > 0) {
        currItem = currentCount[index];
      } else {
        currItem = 0;
      }
      if (queryItem !== currItem && (currItem !== 0)) {
        a += 1;
      } else if (queryItem !== 0 && currItem === 0) {
        a = -10;
      }
      return a;
    }, 0);
    return compare;
  }

  public get getData(): overviewSurveyProps|undefined {
    return this.data;
  }

  public get getTrainings(): Array<string[]> {
    const values = this.trainingData;
    return getUniqueTrainings(values);
  }

  public get getFilterAmount():number {
    let total = 0;
    if (this.location) {
      total += 1;
    }
    if (this.date.length > 0) {
      total += 1;
    }
    return total;
  }
}

export default OverviewFilter;
