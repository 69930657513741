import user from './user';
import surveysFilter from './surveysFilter';
import surveysMortality from './surveysMortality';
import overviewFilter from './overviewFilters';
import trainingFilter from './trainingFilter';
import training from './training';

export default {
  user,
  surveysFilter,
  overviewFilter,
  trainingFilter,
  training,
  surveysMortality,
};
