
import { defineComponent } from 'vue';
import { MailOutlined, EnvironmentOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'Footer',
  components: {
    MailOutlined,
    EnvironmentOutlined,
  },
});
