import {
  VuexModule,
  Module,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import dayjs, { Dayjs } from 'dayjs';
import { AxiosResponse } from 'axios';
import fetch, {
  createSurveysQuery,
  surveysSubProps,
  locationProps,
  organizationOptionProps,
} from '@/utils/request';

type loadingTypes = 'loading' | 'done' | 'inactive'

export interface SurveysFilterProps {
  geographicScope: string;
  location: string;
  date: Dayjs[];
  seasonality: string;
  age: string;
  surveyType: string;
  organization: string;
  data: surveysSubProps[];
  locationOptions: locationProps[];
  queryDiff: string|boolean[];
  loading: loadingTypes;
}

@Module({ namespaced: true })
class SurveysFilter extends VuexModule {
  public geographicScope = ''

  public location = ''

  public date = [] as Dayjs[];

  public seasonality = ''

  public age = '';

  public surveyType = '';

  public organization = '';

  public data:surveysSubProps[] = [];

  public locationOptions: locationProps[] = [];

  public organizationOptions: organizationOptionProps[] = [];

  public queryDiff:any[] = [];

  public loading: loadingTypes = 'inactive';

  @Mutation
  public setGeographicName(name: string):void {
    this.geographicScope = name;
  }

  @Action
  public updateGeographicName(name: string): void {
    this.context.commit('setGeographicName', name);
  }

  @Mutation
  public setLocation(name: string):void {
    this.location = name;
  }

  @Action
  public updateLocation(name: string): void {
    this.context.commit('setLocation', name);
  }

  @Mutation
  public setDate(dateRange: Dayjs[]):void {
    this.date = dateRange;
  }

  @Mutation
  public setQueryDiff():void {
    this.queryDiff = [this.location, this.date, this.age, this.surveyType, this.seasonality, this.organization];
  }

  @Action
  public updateDate(dateRange: Dayjs[]): void {
    this.context.commit('setDate', dateRange || []);
  }

  @Mutation
  public setSeasonality(name: string):void {
    this.seasonality = name;
  }

  @Action
  public updateSeasonality(name: string): void {
    this.context.commit('setSeasonality', name);
  }

  @Mutation
  public setSurveyType(name: string):void {
    this.surveyType = name;
  }

  @Action
  public updateSurveyType(name: string): void {
    this.context.commit('setSurveyType', name);
  }

  @Mutation
  public setAge(name: string):void {
    this.age = name;
  }

  @Action
  public updateAge(name: string): void {
    this.context.commit('setAge', name);
  }

  @Mutation
  public setLoading(name: loadingTypes):void {
    this.loading = name;
  }

  @Action
  public updateLoading(name: loadingTypes): void {
    this.context.commit('setLoading', name);
  }

  @Mutation
  public setLocationOptions(Options: locationProps[]):void {
    this.locationOptions = Options;
  }

  @Action
  public async updateLocationOptions(): Promise<AxiosResponse> {
    return fetch('GET', 'locations', {}, { indicatorNames: ['anthropometry'] })
      .then((res) => {
        this.context.commit('setLocationOptions', res.data);
      }).catch((e) => (e));
  }

  @Mutation
  public setOrganizationOptions(Options: organizationOptionProps[]):void {
    this.organizationOptions = Options;
  }

  @Action
  public async updateOrganizationOptions(): Promise<AxiosResponse> {
    return fetch('GET', 'surveyOrganizations', {}, { indicatorNames: ['anthropometry'] })
      .then((res) => {
        this.context.commit('setOrganizationOptions', res.data);
      }).catch((e) => (e));
  }

  @Mutation
  public setData(data: surveysSubProps[]):void {
    this.data = data;
  }

  @Action
  public async updateData(): Promise<AxiosResponse> {
    const surveysQuery = createSurveysQuery({
      location: this.location,
      geographicScope: this.geographicScope,
      seasonality: this.seasonality,
      age: this.age,
      organization: this.organization,
      organizationOptions: this.organizationOptions,
      surveyType: this.surveyType,
      date: this.date,
    });
    this.context.commit('setLoading', 'loading');
    this.context.commit('setQueryDiff');
    return fetch('GET', 'surveysAnthropometry', {}, { surveysQuery }).then((res) => {
      this.context.commit('setData', res.data.surveys);
      this.context.commit('setLoading', 'done');
      return res;
    }).catch((e) => (e));
  }

  @Mutation
  public setOrganization(name: string):void {
    this.organization = name;
  }

  @Action
  public updateOrganization(name: string): void {
    this.context.commit('setOrganization', name);
  }

  public get getGeographicScope(): string {
    return this.geographicScope;
  }

  public get getLocation(): string {
    return this.location;
  }

  public get getAge(): string {
    return this.age;
  }

  public get getSeasonality(): string {
    return this.seasonality;
  }

  public get getSurveyType(): string {
    return this.surveyType;
  }

  public get getData():surveysSubProps[] {
    return this.data;
  }

  public get getOrganization(): string {
    return this.organization;
  }

  public get getLocationOptions(): locationProps[] {
    return this.locationOptions;
  }

  public get getOrganizationOptions(): organizationOptionProps[] {
    return this.organizationOptions;
  }

  public get getDate(): Dayjs[] {
    return this.date;
  }

  public get getLoading(): loadingTypes {
    return this.loading;
  }

  public get getFilterAmount():number {
    let total = 0;
    if (this.location) {
      total += 1;
    }
    if (this.date.length > 0) {
      total += 1;
    }
    if (this.age) {
      total += 1;
    }
    if (this.seasonality) {
      total += 1;
    }
    if (this.surveyType) {
      total += 1;
    }
    if (this.organization) {
      total += 1;
    }
    return total;
  }

  public get getQueryDiff(): number {
    const queryCount = this.queryDiff;
    const currentCount = [this.location, this.date, this.age, this.surveyType, this.seasonality, this.organization];

    const compare = currentCount.reduce((acc, curr, index) => {
      let a = acc;
      let queryItem:(string|number|Dayjs[]);
      let currItem:(string|number|Dayjs[]);
      if (!queryCount[index]) {
        queryItem = 0;
      } else if (queryCount[index].length > 0) {
        queryItem = queryCount[index];
      } else {
        queryItem = 0;
      }
      if (!currentCount[index]) {
        currItem = 0;
      } else if (currentCount[index].length > 0) {
        currItem = currentCount[index];
      } else {
        currItem = 0;
      }
      if (queryItem !== currItem && (currItem !== 0)) {
        a += 1;
      } else if (queryItem !== 0 && currItem === 0) {
        a = -10;
      }
      return a;
    }, 0);
    return compare;
  }
}

export default SurveysFilter;
