
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { defineComponent, KeepAlive } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    KeepAlive,
    Header,
    Footer,
  },
});
