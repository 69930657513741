import {
  VuexModule,
  Module,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import dayjs, { Dayjs } from 'dayjs';

export interface TrainingFilterProps {
  geographicScope: string;
  location: string;
  date: Dayjs[];
  trainingType: string;
  organization: string;
}

type loadingTypes = 'loading' | 'done' | 'inactive'

@Module({ namespaced: true })
class TrainingFilter extends VuexModule {
  public geographicScope = '';

  public location = '';

  public date = [] as Dayjs[];

  public trainingType = '';

  public organization = '';

  public status:loadingTypes = 'inactive';

  @Mutation
  public setGeographicName(name: string):void {
    this.geographicScope = name;
  }

  @Action
  public updateGeographicName(name: string): void {
    this.context.commit('setGeographicName', name);
    this.context.dispatch('updateStatus', 'loading');
  }

  @Mutation
  public setLocation(name: string):void {
    this.location = name;
  }

  @Action
  public updateLocation(name: string): void {
    this.context.commit('setLocation', name);
    this.context.dispatch('updateStatus', 'loading');
  }

  @Mutation
  public setOrganization(name: string):void {
    this.organization = name;
  }

  @Action
  public updateOrganization(name: string): void {
    this.context.commit('setOrganization', name);
    this.context.dispatch('updateStatus', 'loading');
  }

  @Mutation
  public setStatus(name: loadingTypes):void {
    this.status = name;
  }

  @Action
  public updateStatus(name: string): void {
    this.context.commit('setStatus', name as loadingTypes);
  }

  @Mutation
  public setTrainingType(name: string):void {
    this.trainingType = name;
  }

  @Action
  public updateTrainingType(name: string): void {
    this.context.commit('setTrainingType', name);
    this.context.dispatch('updateStatus', 'loading');
  }

  @Mutation
  public setDate(dateRange: Dayjs[]):void {
    this.date = dateRange;
  }

  @Action
  public updateDate(dateRange: Dayjs[]): void {
    this.context.commit('setDate', dateRange || []);
    this.context.dispatch('updateStatus', 'loading');
  }

  @Action
  public updateFilterData(data: { values: Array<string[]>; }): Array<string[]> {
    const { values } = data;
    let newValues = [] as Array<string[]>;
    if (!(this.location || this.date.length > 0 || this.organization || this.trainingType)) {
      newValues = values;
    } else {
      newValues.push(values[0]);
      values.forEach((v: string[], valueIndex:number) => {
        if (valueIndex === 0) return;
        let isValid = true;
        let index;
        const indexes = [];
        if (this.organization) {
          index = values[0].indexOf('Trainee Organizational Affliation (Specific Name)');
          if ((index <= v.length - 1) && v[index].trim() !== this.organization) {
            isValid = false;
          }
        }
        if (this.trainingType) {
          index = values[0].indexOf('Level of Event');
          if ((index <= v.length - 1) && v[index] !== this.trainingType) {
            isValid = false;
          }
        }
        if (isValid && this.location) {
          if (this.geographicScope === 'National') {
            indexes.push(values[0].indexOf('Country of Training'));
          } else if (this.geographicScope === 'Regional') {
            indexes.push(values[0].indexOf('Region of Training'));
          } else if (this.geographicScope === 'All' || !this.geographicScope) {
            indexes.push(values[0].indexOf('Region of Training'));
            indexes.push(values[0].indexOf('Country of Training'));
          }
          if (!indexes.includes(-1)) {
            let inScope = false;
            indexes.forEach((i:number) => {
              if (i <= v.length - 1 && v[i] && this.location === v[i].trim()) inScope = true;
            });
            if (!inScope) isValid = false;
          }
        }
        if (isValid && this.date.length > 0) {
          index = values[0].indexOf('Year of Training');
          const monthIndex = values[0].findIndex((x:string) => x.trim() === 'Month of Training');
          let d;
          if (index <= v.length - 1 && monthIndex <= v.length - 1) {
            let month;
            if (v[monthIndex] && v[monthIndex].length > 2) {
              month = dayjs(`2022-${v[monthIndex]}-01`).month();
            } else {
              month = parseInt(v[monthIndex], 10) - 1;
            }
            d = dayjs().month(month).year(parseInt(v[index], 10)).day(25);
          }
          // from date
          if (this.date[0] && d) {
            isValid = d.isAfter(this.date[0]);
          }
          if (this.date[1] && d && isValid) {
            d.month(1);
            isValid = d.isBefore(this.date[1]);
          }
        }

        if (isValid) {
          newValues.push(v);
        }
      });
    }
    this.context.commit('setStatus', 'done');
    return newValues;
  }

  public get getDate(): Dayjs[] {
    return this.date;
  }

  public get getLocation(): string {
    return this.location;
  }

  public get getTrainingType(): string {
    return this.trainingType;
  }

  public get getOrganization(): string {
    return this.organization;
  }

  public get getGeographicScope(): string {
    return this.geographicScope;
  }

  public get getStatus():loadingTypes {
    return this.status;
  }

  public get getFilterAmount():number {
    let total = 0;
    if (this.location) {
      total += 1;
    }
    if (this.trainingType) {
      total += 1;
    }
    if (this.organization) {
      total += 1;
    }
    if (this.date.length > 0) {
      total += 1;
    }
    return total;
  }
}

export default TrainingFilter;
