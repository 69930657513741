import axios, { AxiosPromise, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { API_URL, GOOGLE_SHEET_API_KEY } from './constants';
// eslint-disable-next-line
import { anthropometryKeystatProps, SurveyTypes, surveysFiltersQueries, surveysFilterOptionProps, mortalityKeystatProps, overviewByYearProps, overviewByCountryProps } from './surveys';

export interface trainingInfoProps extends AxiosResponse {
  range: string;
  majorDimension: string;
  values: Array<string[]>;
}
/* eslint-disable */
type keystatsPropTypes<T> =  
  T extends 'mort' ? mortalityKeystatProps :
  anthropometryKeystatProps;


export interface surveysProps<T> {
  surveys: surveysSubProps<T>[];
}

export interface surveysSubProps<T = 'anthro'> {
  region: string;
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  administrative_area_level_3: string;
  id: number;
  indicators: string[];
  surveyType: SurveyTypes;
  latitude: number;
  longitude: number;
  surveyMonth: number;
  surveyYear: number;
  surveyName: string;
  organizationName: string;
  geographicAreaName: string;
  geographicCountry: string;
  keystats: keystatsPropTypes<T>;
  previousSurveys?: number;
}
/* eslint-enable */
export interface overviewSurveyProps {
  totalSurveys: number;
  surveysByYear: {
    [index: string]: overviewByYearProps;
  };
  surveysByCountry: {
    [index:string]: overviewByCountryProps;
  };
  organizations: string[];
}

// locations
export interface locationProps {
  region?: string;
  children : locationProps[];
  adminLevel?: number;
  title: string;
}
// organizations
export interface organizationOptionProps {
  id: number;
  name: string;
  type: string;
  setting: string;
  createdAt: string;
}

type AxiosResponseTypes<T> =
  T extends 'traineeData' ? trainingInfoProps :
  T extends 'surveysAnthropometry' ? surveysProps<'anthro'> :
  T extends 'surveysMortality' ? surveysProps<'mort'> :
  T extends 'overviewSurveys' ? overviewSurveyProps :
  T extends 'locations' ? locationProps[] :
  T extends 'organizations' ? organizationOptionProps[] :
  AxiosResponse;

type endpointTypes = 'training' | 'surveysAnthropometry' | 'locations' | 'organizations' | 'surveyOrganizations' | 'traineeData' | 'surveysMortality' | 'overviewSurveys';

// const endpoints
interface endpointsObj {
  [index:string]: string;
}
// params
interface paramsTypes {
  indicatorNames?: string[];
  regions?: string;
  countries?: string[];
  admin1?: string[];
  admin2?: string[];
  admin3?: string[];
  seasonality?: string;
  types?: string[];
  orgs?: number[];
  agegroup?: string;
  from?: string;
  to?: string;
}

// args
interface argTypes {
  SpreadsheetId?: string;
  sheetName?: string;
  indicatorNames?: string[];
  surveysQuery?: surveysFiltersQueries;
}

export const apiParser = (endpoint:string, { SpreadsheetId, sheetName }:argTypes):string => {
  const endpoints:endpointsObj = {
    training: `https://sheets.googleapis.com/v4/spreadsheets/${SpreadsheetId}/values/${sheetName}?key=${GOOGLE_SHEET_API_KEY}`,
    surveysAnthropometry: `${API_URL}/dashboard/survey-results/anthropometry`,
    surveysMortality: `${API_URL}/dashboard/survey-results/mortality`,
    locations: `${API_URL}/locations`,
    organizations: `${API_URL}/organizations`,
    surveyOrganizations: `${API_URL}/organizations/surveys`,
    traineeData: `${API_URL}/trainee-data`,
    overviewSurveys: `${API_URL}/surveys-overview`,
  };

  return endpoints[endpoint];
};

const paramParser = ({ indicatorNames, surveysQuery }:argTypes): paramsTypes|undefined => {
  const params:paramsTypes = {};
  if (indicatorNames) params.indicatorNames = indicatorNames;
  if (surveysQuery) {
    const {
      countries,
      admin1,
      admin2,
      admin3,
      seasonality,
      types,
      orgs,
      agegroup,
      from,
      to,
      regions,
    } = surveysQuery;
    if (agegroup) params.agegroup = surveysQuery.agegroup;
    if (seasonality) params.seasonality = surveysQuery.seasonality;
    if (regions) params.regions = surveysQuery.regions;
    if (orgs) params.orgs = surveysQuery.orgs;
    if (countries) params.countries = surveysQuery.countries;
    if (admin1) params.admin1 = surveysQuery.admin1;
    if (admin2) params.admin2 = surveysQuery.admin2;
    if (admin3) params.admin3 = surveysQuery.admin3;
    if (from) params.from = surveysQuery.from;
    if (to) params.to = surveysQuery.to;
    if (types) params.types = surveysQuery.types;
  }
  return params;
};

/*
// interceptors
axios.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log('response');
  return response;
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log('reject');
  return Promise.reject(error);
});
*/

// execute api call
type methodType = 'get' | 'GET' | 'POST' | 'post' | 'put' | 'PUT' | 'DELETE';

export default function fetch<T extends endpointTypes>(method:methodType,
  endpoint: T, body:unknown = null, {
    SpreadsheetId,
    sheetName,
    indicatorNames,
    surveysQuery,
  }: argTypes = {}):AxiosPromise<AxiosResponseTypes<T>> {
  return axios({
    method,
    url: `${apiParser(endpoint, { SpreadsheetId, sheetName })}`,
    data: body,
    params: paramParser({ indicatorNames, surveysQuery }),
    withCredentials: false,
  });
}

export function createSurveysQuery(filter:surveysFilterOptionProps): surveysFiltersQueries {
  const {
    location,
    date,
    seasonality,
    age,
    surveyType,
    organization,
    geographicScope,
    organizationOptions,
  } = filter;
  const query:surveysFiltersQueries = {};
  if (location) {
    if (geographicScope === 'Regional') {
      query.regions = location;
    } else if (geographicScope === 'National') {
      query.countries = [location];
    } else if (geographicScope === 'Sub-National') {
      query.admin1 = [location];
      query.admin2 = [location];
      query.admin3 = [location];
    }
  }
  if (date && date.length > 0) {
    if (date[0]) query.from = date[0].format();
    if (date.length > 1 && date[1]) {
      query.to = date[1].format();
    } else {
      query.to = dayjs().format();
    }
  }
  if (seasonality) {
    query.seasonality = seasonality;
  }
  if (age) {
    // eslint-disable-next-line
    query.agegroup = age.split(' ')[0];
  }
  if (surveyType) {
    query.types = [surveyType];
  }
  if (organization && organizationOptions) {
    const org = organizationOptions.find((o:organizationOptionProps) => (o.name === organization));
    if (org) query.orgs = [org.id];
  }
  return query;
}
