import { createApp } from 'vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueGtag from 'vue-gtag';
import {
  Dropdown,
  Button,
  Menu,
  Popover,
  AutoComplete,
  Input,
  DatePicker,
  Select,
  Empty,
  Spin,
  message,
} from 'ant-design-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { GOOGLE_MAP_API_KEY, GOOGLE_TAG_KEY } from './utils/constants';
import './assets/css/font.css';
import './assets/css/utility.css';
import './assets/css/app.css';

const app = createApp(App);

app.use(Popover);
app.use(Button);
app.use(Dropdown);
app.use(Menu);
app.use(AutoComplete);
app.use(DatePicker);
app.use(Select);
app.use(Empty);
app.use(Spin);
// @ts-expect-error message type not registered
app.use(message);

app.use(store);
app.use(router);

app.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAP_API_KEY,
  },
});

app.use(VueGtag, {
  config: {
    id: GOOGLE_TAG_KEY,
    params: {
      page_title: 'Global Dashboard',
    },
  },
});
app.mount('#app');
