import {
  VuexModule,
  Module,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import fetch from '@/utils/request';
import { getUniqueTrainings } from '@/utils/functions';
import mockData from './sheetsample';

type stateTypes = 'loading' | 'done' | 'inactive';

interface dataProps {
  range: string;
  majorDimension: string;
  values: Array<string[]>;
  loadingState: stateTypes;
}

@Module({ namespaced: true })
class Training extends VuexModule {
  public data = { values: [[]] as Array<string[]> }

  public filteredData = { values: [[]] as Array<string[]> }

  public loadingState : stateTypes = 'inactive'

  @Mutation
  public setData(newData: dataProps): void {
    this.data = newData;
  }

  @Mutation
  public setFilteredData(newFilteredData: dataProps): void {
    this.filteredData = newFilteredData;
  }

  @Mutation
  public setLoadingState(loadingState: stateTypes): void {
    this.loadingState = loadingState;
  }

  @Action
  public async updateDefaultData(): Promise<any> {
    this.context.commit('setLoadingState', 'loading');
    return fetch('GET', 'traineeData')
      .then((res) => {
        const { values } = res.data;
        const trimData = values.filter((row: string[]) => {
          let isFull = false;
          row.forEach((col:string) => {
            if (!isFull) isFull = !((col && col.includes('N/A')) || col.length === 0);
          });
          return row.length > 4 && isFull;
        });
        this.context.commit('setData', { ...res.data, values: trimData });
        this.context.commit('setFilteredData', { ...res.data, values: trimData });
        this.context.commit('setLoadingState', 'done');
      })
      .catch((e) => {
        console.error(e);
        this.context.commit('setData', mockData);
        this.context.commit('setFilteredData', mockData);
      });
  }

  @Action
  public async updateData():Promise<void> {
    this.context.commit('setLoadingState', 'loading');
    const d = await this.context.dispatch('trainingFilter/updateFilterData', this.data, { root: true });
    this.context.commit('setFilteredData', { ...this.filteredData, values: d });
    this.context.commit('setLoadingState', 'done');
  }

  public get getDefaultValues(): Array<string[]> {
    return this.data.values;
  }

  public get getFilterValues(): Array<string[]> {
    return this.filteredData.values;
  }

  public get getLoadingState(): stateTypes {
    return this.loadingState;
  }

  public get getTrainings(): Array<string[]> {
    const { values } = this.filteredData;
    return getUniqueTrainings(values);
  }
}

export default Training;
